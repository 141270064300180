import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';

const MyContext = createContext();

export const MyProvider = ({ children }) => {
    const [propertyList, setPropertyList] = useState();
    const mProps = {
        propertyList,
    };
    const fetchData = async () => {
        const config = {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        };
        const response = await axios.get('http://localhost/seatslice/getmanagers/', config);
        // const result = await response.json();
        return response;
    };
    async function getData() {
        try {
            const docRef = await fetchData();
            console.log(docRef.data);
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getData();
    },[]);
    return (
        <MyContext.Provider value={mProps}>{children}</MyContext.Provider>
    );
}
export function useMyContext() {
    return useContext(MyContext);
}