import { Box, Button, Container, Grid, Paper, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'

const BuyContent = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [location, setLocation] = useState('');
  const [requirement, setRequirement] = useState('');
  const handleFormSubmit = (event) => {
    event.preventDefault();
    const message = `Name: ${name}%0AEmail: ${email}%0APhone: ${phone}%0ALocation: ${location}%0ARequirement: ${requirement}`; // message to be sent on WhatsApp
    const url = `https://api.whatsapp.com/send?phone=919960447972&text=${message}`; // replace <phone number> with the WhatsApp phone number to which you want to send the message
    window.open(url, '_blank');
  };
  return (
    <div style={{backgroundColor:'#80CED7',minHeight:'90vh'}}>
      <Grid container spacing={2} mt={4}>
        <Grid item xs={12} md={8}>
          <Container>
          <h2><i>Explore the finest properties to <u>purchase</u> in Bhiwandi with our expert team, and find your perfect home or godown.</i></h2>
         
          </Container>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{p:2}}>
            <form>
              <Typography variant="h5" gutterBottom>
                Please Fill Details, Our team will contact you .
              </Typography>
              <Box sx={{mb:2}}>
              <TextField label="Name" fullWidth variant="outlined" value={name} onChange={(e) => setName(e.target.value)} required /></Box>
              <Box sx={{mb:2}}>
              <TextField label="Phone" fullWidth type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} variant="outlined" required /></Box>
              <Box sx={{mb:2}}>
              <TextField label="Email" fullWidth type="email" value={email} onChange={(e) => setEmail(e.target.value)} variant="outlined" required /></Box>
              <Box sx={{mb:2}}>
              <TextField label="Location" fullWidth type="text" value={location} onChange={(e) => setLocation(e.target.value)} variant="outlined" required /></Box>
              <Box sx={{mb:2}}>
              <TextField label="Your Requirement" fullWidth type="text" variant="outlined" value={requirement} onChange={(e) => setRequirement(e.target.value)} multiline required /></Box>
              <Button variant="contained" color="primary" type="submit" onClick={handleFormSubmit}>
                Submit
              </Button>
            </form>
          </Paper>
        </Grid>
    </Grid>
    </div>
  )
}

export default BuyContent