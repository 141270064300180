import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField } from '@mui/material';
import React, { useState } from 'react';
// import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField } from '@material-ui/core';

const PopupDialog = () => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleMobileChange = (event) => {
    setMobile(event.target.value);
  };

  const handleSubmit = () => {
    const email = 'your-email@example.com';
    const subject = 'New Contact Details';
    const body = `Name: ${name}\nMobile: ${mobile}`;

    window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;

    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Open Popup Dialog
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Enter Contact Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your name and mobile number
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            value={name}
            onChange={handleNameChange}
            fullWidth
          />
          <TextField
            margin="dense"
            id="mobile"
            label="Mobile Number"
            type="tel"
            value={mobile}
            onChange={handleMobileChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PopupDialog;
