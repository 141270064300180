import { Box, Container, CssBaseline } from '@mui/material'
import React from 'react'
import { colors } from '../../Component/utils/Colors';

const Slider = () => {
  const highlightStyle = {
    // backgroundColor: '#ffc107',
    color: colors.primary,
    padding: '2px 4px',
  };
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', }} className='slider' data-aos="fade-up">
      <CssBaseline />
      <Container>
        <h1 className='hero'>Discover your dream <span class="underline">home</span> & <span class="underline">warehouses</span> in Bhiwandi</h1>
        <p className='subhero'>
          {/* Let's simplify your home & warehouses (godowns) {' '}<br/>
          <span style={highlightStyle}>buying</span>,{' '}
          <span style={highlightStyle}>selling</span>,{' '}
          <span style={highlightStyle}>renting</span>, and{' '}
          <span style={highlightStyle}>loan</span> needs in Bhiwandi. */}
          Let's Simplify your Home, Shop, Office and Warehouse ( Godowns) , <br/>
Buying , Selling and Loan Requirement in Bhiwandi.
        </p>
        <div>
      {/* <h1 className="title">
        Let's simplify your home &amp; warehouses <br />
        <span className="subhero">(godowns) buying, selling, renting, and loan needs in Bhiwandi city.</span>
      </h1> */}
      <div className="options" id="options">
        <div className="option option-buying">
          <a href="/buy">Buying</a>
        </div>
        <div className="option option-selling">
          <a href="/sell">List Property</a>
        </div>
        <div className="option option-renting">
          <a href="/rent">Renting</a>
        </div>
        <div className="option option-loan">
          <a href="/homeloan">Home Loan</a>
        </div>
      </div>
    </div>
      </Container>
    </Box>
  )
}

export default Slider