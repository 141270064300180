import React, { useState } from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import { TextField, Button, Grid, Typography, Box, Paper } from '@mui/material';

const styles = {
  root: {
    backgroundColor: '#2196f3',
    color: '#fff',
    paddingRight: '4rem',
    paddingLeft: '4rem',
    minHeight: '100vh',
  },
  heading: {
    fontWeight: 700,
    fontSize: '2.5rem',
    marginBottom: '2rem',
    paddingTop: '2rem'
  },
}
const EMI_Calculator = () => {
  const [principal, setPrincipal] = useState(0);
  const [rate, setRate] = useState(0);
  const [tenure, setTenure] = useState(0);
  const [emi, setEmi] = useState(0);

  const calculateEmi = () => {
    const r = rate / (12 * 100);
    const n = tenure;
    const emi = (principal * r * (1 + r) ** n) / ((1 + r) ** n - 1);
    setEmi(emi.toFixed(2));
  };

  const COLORS = ['#0088FE', '#00C49F'];

  const calculateChartData = () => {
    const r = rate / (12 * 100);
    // const n = tenure;
    const monthlyInterest = principal * r;
    const monthlyPrincipal = emi - monthlyInterest;
    const data = [
      { name: 'Principal', value: monthlyPrincipal },
      { name: 'Interest', value: monthlyInterest },
    ];
    return data;
  };

  return (
    <div style={styles.root}>
      <Grid container spacing={2} mt={4}>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2 }}>
            <form>
              <Typography variant="h5" gutterBottom>
                Emi Calculator
              </Typography>
              <Box sx={{ mb: 2 }}>
                <TextField label="Principal" fullWidth type="number" value={principal} onChange={(e) => setPrincipal(e.target.value)} /></Box>
              <Box sx={{ mb: 2 }}>
                <TextField label="Rate of Interest (% p.a.)" fullWidth type="number" value={rate} onChange={(e) => setRate(e.target.value)} /></Box>
              <Box sx={{ mb: 2 }}>
                <TextField label="Tenure (in months)" type="number" fullWidth value={tenure} onChange={(e) => setTenure(e.target.value)} /></Box>
              <Button variant="contained" color="primary" onClick={calculateEmi}>
                Calculate EMI
              </Button>
            </form>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          {emi !== 0 && (
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6">EMI: {emi}</Typography>
              <PieChart width={400} height={400}>
                <Pie
                  data={calculateChartData()}
                  cx={200}
                  cy={200}
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {calculateChartData().map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </Paper>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default EMI_Calculator;
