import React from 'react'
import Navbar from '../../Component/Navbar'
import EMI_Calculator from './EmiCalculator'

const Emi = () => {
  return (
    <>
    <Navbar/>
    <EMI_Calculator/>
    </>
  )
}

export default Emi