import React from 'react'
import Navbar from '../../Component/Navbar'
import CommercialContent from './CommercialContent'

const Commercial = () => {
  return (<>
  <Navbar/>
  <CommercialContent/>
  </>
  )
}

export default Commercial