import React from 'react'
import { AppBar, Box, CssBaseline, Toolbar, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { colors } from './utils/Colors'

const Navbar = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" style={{ backgroundColor: colors.white, color: colors.primary, borderBottomWidth: 1, borderBottomColor: colors.gray200, borderBottomStyle: 'solid' }} elevation={0}>
        <Toolbar
        >
          <Box style={{ flexGrow: 1 }}>
          <NavLink to={'/'} style={{textDecoration:'none'}}>
            <Typography
              noWrap
              fontSize={'1.5rem'}
              className={'navbar-brandLogo'}
            >
              Bhiwandi Properties
            </Typography>
            </NavLink>
          </Box>
          <NavLink to={'/'} className='menu'>Home</NavLink>
          <NavLink to={'/buy'} className='menu'>Buy</NavLink>
          <NavLink to={'/rent'} className='menu'>Rent</NavLink>
          <NavLink to={'/commercial'} className='menu'>Commercial</NavLink>
          <NavLink to={'/homeloan'} className='menu'>Home Loan</NavLink>
          <NavLink to={'/emi'} className='menu'>Emi Calculator</NavLink>
          <NavLink to={'/contact'} className='menu'>Contact Us</NavLink>
          <NavLink to={'/sell'} className='menu'>List Property</NavLink>
        </Toolbar>
      </AppBar>
      <div style={{height:'30px'}}/>
    </Box>
  )
}

export default Navbar