import React from 'react'
import { Box, Button, Card, CardActions, CardContent, CardMedia, IconButton, styled, Typography } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { Share } from '@mui/icons-material';
const SellTag = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  padding: '4px 8px',
  backgroundColor: '#2ecc71',
  color: '#fff',
  fontWeight: 'bold',
  fontSize: '14px',
  borderRadius: '4px 0 0 4px',
  textTransform: 'capitalize'
});
const ProjectCard = (props) => {
  const handleShare = () => {
    navigator.share({
      title: document.title,
      url: props.url
    })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing:', error));
  };
  const handleWhatsAppClick = () => {
    window.open('https://api.whatsapp.com/send?phone=919960447972', '_blank');
  };
  return (
    <Card sx={{ position: 'relative', mb: 2 }}>
      <NavLink to={'/details?q=' + props.id} style={{ textDecoration: 'none' }}>
        <Box>
          <SellTag>{`For ${props.cat}`}</SellTag>
        </Box>
        <CardMedia
         sx={{ height: 0, paddingTop: '56.25%',paddingLeft:10,paddingRight:10, objectFit: 'cover' }}
          image={props.pic}
          title={props.title}
        />
        <CardContent>
          <Typography gutterBottom variant="p" component="div" sx={{ fontWeight: 'bold' }}>
            {props.title.length > 26 && window.innerWidth > 600 ? `${props.title.substring(0, 26)}...` : props.title}
          </Typography>
          <Typography gutterBottom variant="p" component="div" color="black">
            {props.builder}
          </Typography>
          <Typography variant="p" color="black">
            {`₹ ${props.price}`}
          </Typography>
        </CardContent></NavLink>
      <CardActions sx={{ justifyContent: 'space-between' }}>
        <Button size="small" variant='outlined' onClick={handleWhatsAppClick}>Contact</Button>
        <IconButton aria-label="share" onClick={handleShare}>
          <Share />
        </IconButton>
      </CardActions>
    </Card>
  )
}

export default ProjectCard