import { Container } from '@mui/material';
import React from 'react'
import { useSearchParams } from 'react-router-dom';
import { propertyList } from '../../Component/utils/propertylist';

const DetailsContent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("q");
  const property = propertyList.find(item => item.id === parseInt(id, 10));
  return (
    <Container sx={{ mt: 4 }}>
      <p>{property.title}</p>
    </Container>
  )
}

export default DetailsContent