import { Grid } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import ServiceCard from '../../Component/ServiceCard'
import { images } from '../../Component/utils/Images'

const OurServices = () => {
    return (
        <Container>
            <h2 className='service-title'>Our Services</h2>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 4, sm: 2, md: 3 }}>
                <Grid item xs={6} md={2}>
                    <ServiceCard url='/buy' images={images.buyImg} title='Buy' />
                </Grid>
                <Grid item xs={6} md={2}>
                    <ServiceCard url='/rent' images={images.buyImg} title='Rent' />
                </Grid>
                <Grid item xs={6} md={2}>
                    <ServiceCard url='/commercial' images={images.buyImg} title='Commercial' />
                </Grid>
                <Grid item xs={6} md={2}>
                    <ServiceCard url='/godowns' images={images.buyImg} title='Warehouses Godowns' />
                </Grid>
                <Grid item xs={6} md={2}>
                    <ServiceCard url='/homeloan' images={images.buyImg} title='Home Loan' />
                </Grid>
                <Grid item xs={6} md={2}>
                    <ServiceCard url='/sell' images={images.buyImg} title='List Property' />
                </Grid>
            </Grid>
        </Container>
    )
}

export default OurServices