import { images } from "./Images";

export const propertyList = [
    {
      "id": 1,
      "title": "Ultima 2 BHK",
      "image": images.tataamantra,
      "price": "52.9 Lakhs Onwards",
      "area": "1200 sq. ft.",
      "location": "New York City",
      "cat": "sell",
      "developerid": 12345,
      "name": "Tata Amantra",
      "floor": 5,
      "totalfloor": 10,
      "facing": "South",
      "furnishedstatus": "Unfurnished",
      "powerbackup": true,
      "lift": true,
      "clubhouse": true,
      "swimmingpool": true,
      "gymnasium": true,
      "reservedparking": true,
      "created_at": "2022-03-01T12:00:00Z",
      "ipaddress": "192.168.0.1",
      "current_address": "123 Main St, New York, NY 10001"
    },
    {
      "id": 2,
      "title": "Maxima 3 BHK",
      "image": images.tataamantra,
      "price": "74.9 Lakhs Onwards",
      "area": "1200 sq. ft.",
      "location": "New York City",
      "cat": "sell",
      "developerid": 12345,
      "name": "Tata Amantra",
      "floor": 5,
      "totalfloor": 10,
      "facing": "South",
      "furnishedstatus": "Unfurnished",
      "powerbackup": true,
      "lift": true,
      "clubhouse": true,
      "swimmingpool": true,
      "gymnasium": true,
      "reservedparking": true,
      "created_at": "2022-03-01T12:00:00Z",
      "ipaddress": "192.168.0.1",
      "current_address": "123 Main St, New York, NY 10001"
    },
    
    {
      "id": 3,
      "title": "Ultima 2 BHK",
      "image": images.shubhvasturealty,
      "price": "52.9 Lakhs Onwards",
      "area": "1200 sq. ft.",
      "location": "New York City",
      "cat": "sell",
      "developerid": 12345,
      "name": "Tata Amantra",
      "floor": 5,
      "totalfloor": 10,
      "facing": "South",
      "furnishedstatus": "Unfurnished",
      "powerbackup": true,
      "lift": true,
      "clubhouse": true,
      "swimmingpool": true,
      "gymnasium": true,
      "reservedparking": true,
      "created_at": "2022-03-01T12:00:00Z",
      "ipaddress": "192.168.0.1",
      "current_address": "123 Main St, New York, NY 10001"
    },
    {
      "id": 4,
      "title": "Mahindra",
      "image": images.mahindra,
      "price": "52.9 Lakhs Onwards",
      "area": "1200 sq. ft.",
      "location": "New York City",
      "cat": "sell",
      "developerid": 12345,
      "name": "Tata Amantra",
      "floor": 5,
      "totalfloor": 10,
      "facing": "South",
      "furnishedstatus": "Unfurnished",
      "powerbackup": true,
      "lift": true,
      "clubhouse": true,
      "swimmingpool": true,
      "gymnasium": true,
      "reservedparking": true,
      "created_at": "2022-03-01T12:00:00Z",
      "ipaddress": "192.168.0.1",
      "current_address": "123 Main St, New York, NY 10001"
    }
  ]