import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Details from './Pages/Details';
import Error from './Pages/Error';
import Home from './Pages/Home';
import PopupDialog from './Pages/Popup';
import Buy from './Pages/Buy';
import Sell from './Pages/Sell';
import Rent from './Pages/Rent';
import HomeLoan from './Pages/HomeLoan';
import Commercial from './Pages/Commercial';
import Godowns from './Pages/Godowns';
import Emi from './Pages/Emi';
import Contact from './Pages/Contact';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='details' element={<Details />} />
        <Route path='pop' element={<PopupDialog />} />
        <Route path='buy' element={<Buy />} />
        <Route path='sell' element={<Sell />} />
        <Route path='rent' element={<Rent />} />
        <Route path='homeloan' element={<HomeLoan />} />
        <Route path='commercial' element={<Commercial />} />
        <Route path='godowns' element={<Godowns />} />
        <Route path='emi' element={<Emi />} />
        <Route path='contact' element={<Contact />} />
        <Route path="/details/:id" component={<Details/>} />
        
        <Route path='*' element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
