import React from 'react'
import Navbar from '../../Component/Navbar'
import RentContent from './RentContent'

const Rent = () => {
  return (
    <>
    <Navbar/>
    <RentContent/>
    </>
  )
}

export default Rent