import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material'
import React from 'react'

const GodownsContent = () => {
  return (
    <Grid container spacing={2} mt={4}>
        <Grid item xs={12} md={2}></Grid>
        <Grid item xs={12} md={8}>
          <Paper sx={{p:2}}>
            <form>
              <Typography variant="h5" gutterBottom>
                Please Fill Details, Our team will contact you .
              </Typography>
              <Box sx={{mb:2}}>
              <TextField label="Name" fullWidth variant="outlined" required /></Box>
              <Box sx={{mb:2}}>
              <TextField label="Phone" fullWidth type="tel" variant="outlined" required /></Box>
              <Box sx={{mb:2}}>
              <TextField label="Email" fullWidth type="email" variant="outlined" required /></Box>
              <Box sx={{mb:2}}>
              <TextField label="Location" fullWidth type="text" variant="outlined" required /></Box>
              <Box sx={{mb:2}}>
              <TextField label="Your Requirement" fullWidth type="text" variant="outlined" multiline required /></Box>
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </form>
          </Paper>
        </Grid>
        <Grid item xs={12} md={2}></Grid>
    </Grid>
  )
}

export default GodownsContent