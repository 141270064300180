import { Box, Button, Container, Grid, Paper, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'

const CommercialContent = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [location, setLocation] = useState('');
  const [requirement, setRequirement] = useState('');
  const handleFormSubmit = (event) => {
    event.preventDefault();
    const message = `Name: ${name}%0AEmail: ${email}%0APhone: ${phone}%0ALocation: ${location}%0ARequirement: ${requirement}`; // message to be sent on WhatsApp
    const url = `https://api.whatsapp.com/send?phone=919960447972&text=${message}`; // replace <phone number> with the WhatsApp phone number to which you want to send the message
    window.open(url, '_blank');
  };
  return (
    <div style={{ backgroundColor: '#C5D3E7', minHeight: '90vh' }}>
      <Grid container spacing={2} mt={4}>
        <Grid item xs={12} md={8}>
          <Container>
            <h2><i>Unlock your business potential with our strategic <u>commercial</u> properties</i></h2>
            <p>Welcome to our commercial property listings, where we provide prime commercial properties to help take your business to the next level. Our strategic properties are designed to elevate your brand and provide ample space for your growing business needs.</p>
            <p>Our properties are located in some of the most desirable locations, ensuring maximum visibility and accessibility for your business. We offer a wide range of commercial properties to suit your unique needs, from office spaces to retail shops to warehouses.</p>
            <p>Our team is committed to providing exceptional service to help you find the perfect commercial property to suit your business needs. We understand the importance of finding the right space for your business, and we work tirelessly to ensure that you find a property that meets all of your requirements.</p>
            <p>So if you're looking to expand your business, upgrade your business space, or maximize your profits, look no further than our top-rated commercial property options. Experience business growth like never before with our unbeatable commercial properties.</p>
          </Container>
        </Grid>
        <Grid item xs={12} md={4}>
        <Paper sx={{p:2}}>
            <form>
              <Typography variant="h5" gutterBottom>
                Please Fill Details, Our team will contact you .
              </Typography>
              <Box sx={{mb:2}}>
              <TextField label="Name" fullWidth variant="outlined" value={name} onChange={(e) => setName(e.target.value)} required /></Box>
              <Box sx={{mb:2}}>
              <TextField label="Phone" fullWidth type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} variant="outlined" required /></Box>
              <Box sx={{mb:2}}>
              <TextField label="Email" fullWidth type="email" value={email} onChange={(e) => setEmail(e.target.value)} variant="outlined" required /></Box>
              <Box sx={{mb:2}}>
              <TextField label="Location" fullWidth type="text" value={location} onChange={(e) => setLocation(e.target.value)} variant="outlined" required /></Box>
              <Box sx={{mb:2}}>
              <TextField label="Your Requirement" fullWidth type="text" variant="outlined" value={requirement} onChange={(e) => setRequirement(e.target.value)} multiline required /></Box>
              <Button variant="contained" color="primary" type="submit" onClick={handleFormSubmit}>
                Submit
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default CommercialContent