import React from 'react'
import Navbar from '../../Component/Navbar'
import GodownsContent from './GodownsContent'

const Godowns = () => {
  return (
    <>
    <Navbar/>
    <GodownsContent/>
    </>
  )
}

export default Godowns