import React from 'react'
import Navbar from '../../Component/Navbar'
import SellContent from './SellContent'

const Sell = () => {
  return (
    <>
    <Navbar/>
    <SellContent/>
    </>
  )
}

export default Sell