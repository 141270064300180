import React from 'react'
import Navbar from '../../Component/Navbar'
import { BsWhatsapp } from 'react-icons/bs'
import Slider from './Slider'
import OurServices from './ourservices'
import Handpicked from './handpicked'

const Home = () => {
  return (
    <>
      <Navbar />
      <Slider />
      <OurServices />
      <Handpicked />
      <a href="https://api.whatsapp.com/send?phone=919960447972" className="float" target="_blank" rel="noreferrer">
        <BsWhatsapp className='my-float' />
      </a>
    </>
  )
}

export default Home