import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'

const styles = {
  root: {
    backgroundColor: '#3FDEB4',
    color: '#fff',
    paddingRight: '4rem',
    paddingLeft: '4rem',
    minHeight: '100vh',
  },
  heading: {
    fontWeight: 700,
    fontSize: '2.5rem',
    marginBottom: '2rem',
    paddingTop: '2rem'
  },
}
const SellContent = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [requirement, setRequirement] = useState('');
  const handleFormSubmit = (event) => {
    event.preventDefault();
    const message = `Name: ${name}%0AEmail: ${email}%0APhone: ${phone}%0AProperty%20Details: ${requirement}`; // message to be sent on WhatsApp
    const url = `https://api.whatsapp.com/send?phone=919960447972&text=${message}`; // replace <phone number> with the WhatsApp phone number to which you want to send the message
    window.open(url, '_blank');
  };
  return (
    <div style={styles.root}>
      <Grid container spacing={2} mt={4}>
        <Grid item xs={12} md={8}>
          <Typography variant="h1" sx={styles.heading}>
          Let us help you showcase your property to potential buyers or renters.
          </Typography>
          <h3>
          At our company, we're committed to providing our clients with the highest level of service and expertise in the real estate industry. Here are just a few reasons why you should choose us for all of your real estate needs:
          </h3>
          <p>
          <b>Experience:</b> Our team of experienced professionals has a proven track record of success in the real estate industry, with a deep understanding of the local market and the skills needed to navigate even the most complex transactions.
          </p>
          <p>
          <b>Personalized Service:</b> We understand that every client is unique, which is why we offer personalized service to meet your specific needs. We'll work with you one-on-one to understand your goals and develop a strategy that's tailored to your unique situation.
          </p>
          <p><b>Extensive Network:</b> With years of experience in the local real estate market, we've built an extensive network of buyers, sellers, and industry professionals. We'll leverage this network to help you achieve your real estate goals quickly and efficiently.</p>
          <p><b>Expert Guidance:</b> Whether you're buying, selling, or renting, we'll provide you with expert guidance every step of the way. We'll explain the process in detail, answer all of your questions, and keep you informed throughout the entire transaction.</p>
          <p><b>Comprehensive Services:</b> From property valuation to marketing to legal assistance, we offer a wide range of services to meet all of your real estate needs. Whether you're looking to sell, buy, or rent a property, we've got you covered.</p>
        </Grid>
        <Grid item xs={12} md={4}>
        <Paper sx={{p:2}}>
            <form>
              <Typography variant="h5" gutterBottom>
                Please Fill Details, Our team will contact you .
              </Typography>
              <Box sx={{mb:2}}>
              <TextField label="Name" value={name} onChange={(e) => setName(e.target.value)} fullWidth variant="outlined" required /></Box>
              <Box sx={{mb:2}}>
              <TextField label="Phone" fullWidth type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} variant="outlined" required /></Box>
              <Box sx={{mb:2}}>
              <TextField label="Email" fullWidth type="email" value={email} onChange={(e) => setEmail(e.target.value)}  variant="outlined" required /></Box>
              <Box sx={{mb:2}}>
              <TextField label="Property Details" fullWidth value={requirement} onChange={(e) => setRequirement(e.target.value)}  type="text" variant="outlined" multiline required /></Box>
              <Button variant="contained" color="primary" type="submit" onClick={handleFormSubmit}>
                Submit
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default SellContent