import React from 'react'
import Navbar from '../../Component/Navbar'
import ContactContent from './ContactContent'

const Contact = () => {
  return (
    <>
    <Navbar/>
    <ContactContent/>
    </>
  )
}

export default Contact