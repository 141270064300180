import { Grid, Typography, Paper, Container } from '@mui/material';
import React from 'react';

const ContactContent = () => {
  return (
    <div style={{backgroundColor:'#DDDDE4',minHeight:'100vh'}}>
    <Container sx={{mt:4}}>
        <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4" sx={{pt:4}}>Contact Us</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={3} style={{ padding: '2rem' }}>
          <Typography variant="h5" gutterBottom>Address:</Typography>
          <Typography variant="body1">
            Perfect Finance Solutions<br />
            202, Varala Niwas, Second Floor,<br />
            Opposite Manoj Katekar Bunglow,<br />
            Kamatghar, Bhiwandi 421302,<br />
            Dist Thane
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={3} style={{ padding: '2rem' }}>
          <Typography variant="h5" gutterBottom>Contacts:</Typography>
          <Typography variant="body1">
            Mob no. 9960447972<br />
            Mob no. 9545518652<br />
            Email mutkiri.shankar@gmail.com
          </Typography>
        </Paper>
      </Grid>
    </Grid>
    </Container>
</div>
  );
}

export default ContactContent;
