import { Grid } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import ProjectCard from '../../Component/ProjectCard'
import { propertyList } from '../../Component/utils/propertylist'

const Handpicked = () => {
  return (
    <Container>
      <h2 className='service-title'>Handpicked Projects</h2>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 4, sm: 2, md: 3 }}>
        {propertyList ? propertyList.map(property => (
          <Grid item xs={12} md={3} key={property.id}>
            <ProjectCard title={property.title} pic={property.image} id={property.id} price={property.price} area={property.area} builder={property.name} cat={property.cat} />
          </Grid>
        )) : null}
      </Grid>
    </Container>
  )
}

export default Handpicked