import React from 'react'
import Navbar from '../../Component/Navbar'
import BuyContent from './BuyContent'

const Buy = () => {
  return (
    <>
    <Navbar />
    <BuyContent/>
    </>
  )
}

export default Buy