import { Box, Typography } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";


const ServiceCard = (props) => {
  return (
    <NavLink to={props.url} style={{ textDecoration: 'none' }}>
      <Box className='service-cardbox'>
        <Typography variant="p" component="p" className="text-center">{props.title}</Typography>
      </Box>
    </NavLink>
  );
};

export default ServiceCard;
