import React from 'react'
import Navbar from '../../Component/Navbar'
import HomeLoanContent from './HomeLoanContent'

const HomeLoan = () => {
  return (
  <>
  <Navbar/>
  <HomeLoanContent/>
  </>
  )
}

export default HomeLoan