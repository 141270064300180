import { Box, Button, Container, Grid, Paper, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'

const RentContent = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [location, setLocation] = useState('');
  const [requirement, setRequirement] = useState('');
  const handleFormSubmit = (event) => {
    event.preventDefault();
    const message = `Name: ${name}%0AEmail: ${email}%0APhone: ${phone}%0ALocation: ${location}%0ARequirement: ${requirement}`; // message to be sent on WhatsApp
    const url = `https://api.whatsapp.com/send?phone=919960447972&text=${message}`; // replace <phone number> with the WhatsApp phone number to which you want to send the message
    window.open(url, '_blank');
  };
  return (
    <div style={{backgroundColor:'#B4AFD5',minHeight:'90vh'}}>
      <Grid container spacing={2} mt={4}>
        <Grid item xs={12} md={8}>
          <Container>
          <h2><i>Discover the best <u>rental</u> Home and Godowns in Bhiwandi with our expert team.</i></h2>
          <p>Welcome to our rental properties in Bhiwandi, where we pride ourselves on providing the best rental homes and godowns in the city. We understand the importance of finding the perfect rental property, whether it's for your family or your business, and we are committed to helping you find exactly what you need.</p>
          <p>Our rental homes in Bhiwandi are designed to provide you with the best possible living experience. We offer a wide range of options, from comfortable apartments to luxurious villas, all equipped with modern amenities to suit your lifestyle. We ensure that our properties are well-maintained and up to the highest standards, so you can enjoy a comfortable and hassle-free stay.</p>
          <p>For businesses looking for extra storage space, our godowns in Bhiwandi are the perfect solution. Our godowns are spacious, secure, and offer ample storage space to meet all your business needs. Our team is always available to help you find the perfect godown for your business operations.</p>
          <p>So if you are looking for the best rental homes and godowns in Bhiwandi, look no further! We are committed to providing you with the best possible rental experience and helping you find the perfect property to suit your needs.</p>
          </Container>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{p:2}}>
            <form>
              <Typography variant="h5" gutterBottom>
                Please Fill Details, Our team will contact you .
              </Typography>
              <Box sx={{mb:2}}>
              <TextField label="Name" fullWidth variant="outlined" value={name} onChange={(e) => setName(e.target.value)} required /></Box>
              <Box sx={{mb:2}}>
              <TextField label="Phone" fullWidth type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} variant="outlined" required /></Box>
              <Box sx={{mb:2}}>
              <TextField label="Email" fullWidth type="email" value={email} onChange={(e) => setEmail(e.target.value)} variant="outlined" required /></Box>
              <Box sx={{mb:2}}>
              <TextField label="Location" fullWidth type="text" value={location} onChange={(e) => setLocation(e.target.value)} variant="outlined" required /></Box>
              <Box sx={{mb:2}}>
              <TextField label="Your Requirement" fullWidth type="text" variant="outlined" value={requirement} onChange={(e) => setRequirement(e.target.value)} multiline required /></Box>
              <Button variant="contained" color="primary" type="submit" onClick={handleFormSubmit}>
                Submit
              </Button>
            </form>
          </Paper>
        </Grid>
    </Grid>
    </div>
  )
}

export default RentContent