import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
const styles = {
  root: {
    backgroundColor: '#2196f3',
    color: '#fff',
    paddingRight: '4rem',
    paddingLeft: '4rem',
    minHeight: '90vh',
  },
  heading: {
    fontWeight: 700,
    fontSize: '2.5rem',
    marginBottom: '2rem',
    paddingTop: '2rem'
  },
}
const HomeLoanContent = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [requirement, setRequirement] = useState('');
  const handleFormSubmit = (event) => {
    event.preventDefault();
    const message = `Name: ${name}%0AEmail: ${email}%0APhone: ${phone}%0ARequirement: ${requirement}`; // message to be sent on WhatsApp
    const url = `https://api.whatsapp.com/send?phone=919960447972&text=${message}`; // replace <phone number> with the WhatsApp phone number to which you want to send the message
    window.open(url, '_blank');
  };
  return (
    <div style={styles.root}>
      <Grid container spacing={2} mt={4}>
        <Grid item xs={12} md={8}>
          <Typography variant="h1" sx={styles.heading}>
            Get Your Dream Home with Our Home Loans
          </Typography>
          <p>
            ✓ Our home loans offer competitive rates and flexible payment options, making it easier than ever to make your dream home a reality.
          </p>
          <p>
            ✓ Make your dream home a reality with our hassle-free home loans.
          </p>
          <p>
            ✓ Move into your dream home faster with our easy-to-apply home loans.
          </p>
          <p>✓ Our competitive rates and flexible payment options make homeownership a reality.</p>
          <p>✓ Take the first step towards homeownership with our affordable home loans.</p>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2 }}>
            <form>
              <Typography variant="h5" gutterBottom>
                Please Fill Details, Our team will contact you .
              </Typography>
              <Box sx={{ mb: 2 }}>
                <TextField label="Name" value={name} onChange={(e) => setName(e.target.value)}  fullWidth variant="outlined" required /></Box>
              <Box sx={{ mb: 2 }}>
                <TextField label="Phone" value={phone} onChange={(e) => setPhone(e.target.value)}  fullWidth type="tel" variant="outlined" required /></Box>
              <Box sx={{ mb: 2 }}>
                <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)}  fullWidth type="email" variant="outlined" required /></Box>
              <Box sx={{ mb: 2 }}>
                <TextField label="Your Requirement" value={requirement} onChange={(e) => setRequirement(e.target.value)}  fullWidth type="text" variant="outlined" multiline required /></Box>
              <Button variant="contained" color="primary" type="submit" onClick={handleFormSubmit}>
                Submit
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default HomeLoanContent