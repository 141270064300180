import { Box } from '@mui/material'
import React from 'react'
import Navbar from '../../Component/Navbar'
import { images } from '../../Component/utils/Images'

const Error = () => {
  return (
    <>
      <Navbar />
      <Box sx={{ height: window.innerHeight,display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
      <img
            src={`${images.error}`}
            alt={'Error'}
            width={'40%'}
            loading="lazy"
          />
      </Box>
    </>
  )
}

export default Error